<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          v-bind="mainProps"
          src="@/assets/images/logo/logo_grupovia.png"
          fluid
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center"
      >
        <div
          class="
            w-100
            d-lg-flex
            align-items-center
            justify-content-center
            px-5
            mt-3
          "
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Esqueceu sua senha?
          </b-card-title>
          <b-card-text class="mb-2">
            Verifique a caixa de e-mail e siga as instruções
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    @focus="error.show = false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div
                v-if="error.show"
                class="text-danger text-center mb-1 mt-1"
              >
                {{ error.text }}
              </div>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Envio de link de redefinição
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Voltar para tela de login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import store from '@/store/index';
import UserService from '@/service/user/user';

import { callLoading, removeDarkMode } from '@/service/utilities/index';

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BImg,
        BForm,
        BButton,
        BFormGroup,
        BFormInput,
        BCardTitle,
        BCardText,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.png'),
            // validation
            required,
            email,
            mainProps: { width: 270, height: 90, class: 'm2' },

            error: {
                text: 'O email inserido não pertence a uma conta. Verifique seu email e tente novamente.',
                show: false,
            },
        };
    },
    computed: {
        imgUrl() {
            return this.sideImg;
        },
    },
    created() {
        removeDarkMode();
    },
    methods: {
        async forgotPassword() {
            callLoading(true, true);
            const response = await UserService.forgotPassword({ email: this.userEmail });

            const { status } = response;

            if (status === 200) {
                this.modalSuccess('E-mail enviado com sucesso');

                this.$router.push('/login');
            } else if (status === 422) { this.error.show = true; }

            callLoading(false);
        },

        async validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.forgotPassword();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
</style>
